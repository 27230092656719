.toggleHandler{
    width: 60px;
    height: 60px;
    cursor: pointer;
    margin-left: auto;
    margin-top: 20px;
    background-color: #45B;
    border-radius: 40px;
}

.icon{
    font-size: 30px;
    color: #ffffff;
    margin-top: 25%;
}

.quick-buttons-container .quick-buttons{
    display: flex;
    flex-direction: row;
}

.quick-buttons-container {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.quick-buttons-container::-webkit-scrollbar {
    /* Hide scrollbar for Chrome, Safari and Opera */
    display: none;
}

.loader {
    position: relative;
    bottom: 0%;
    left: 0;
    right: 0%;
    background-color: white;
    z-index: 99;
    display: none;
    text-align: center;
    width: 100%;
    padding-top: 25px;
}

.rcw-messages-container::-webkit-scrollbar {
    /* Hide scrollbar for Chrome, Safari and Opera */
    display: none;
}
  
  /* Hide scrollbar for IE, Edge and Firefox */
  .rcw-messages-container {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }

  .rcw-conversation-container .rcw-header {
      padding: 0px;
  }

  .rcw-conversation-container .rcw-header {
      background-color: #45B;
  }

  .rcw-response .rcw-message-text, .rcw-client .rcw-message-text{
      padding: 8px;
  }

  .rcw-message-text p{
      font-size: 15px;
      color: #111;
  }

  .quick-button{
      padding: 8px 32px;
      margin-top: 8px;
  }

  button{
    padding: 6px 14px;
  }

  .quick-buttons-container{
      white-space: pre-wrap;
  }